<template>
  <div
    :style="{backgroundColor: bgColor}"
    :class="['label-style text-truncate', isLightColor? 'black--text' : 'white--text']"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    color: {},
    required: {
      default: false
    }
  },
  data() {
    return {
      colorMap: {
        "#bdc3c7": 0,
        "#7f8c8d": 1,
        "#e74c3c": 2,
        "#e67e22": 3,
        "#f1c40f": 4,
        "#34495e": 5,
        "#9b59b6": 6,
        "#3498db": 7,
        "#2ecc71": 8,
        "#1abc9c": 9
      }
    };
  },
  computed: {
    bgColor() {
      return Object.keys(this.colorMap)[this.color | 0];
    },
    isLightColor() {
      const hex = this.bgColor.replace("#", "");
      const c_r = parseInt(hex.substr(0, 2), 16);
      const c_g = parseInt(hex.substr(2, 2), 16);
      const c_b = parseInt(hex.substr(4, 2), 16);
      const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;
      return brightness > 155;
    }
  }
};
</script>

<style lang="scss" scoped>
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}
.label-style {
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 7px;
  font-size: 11.5px;
}
</style>